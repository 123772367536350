import React from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { FormattedMessage } from 'react-intl';

import Layout from '../components/Layout';
import LocalizedLink from '../components/LocalizedLink';
import HeadMeta from '../components/HeadMeta';

const metaData = {
  en: {
    title: `TriDyme | About`,
    description: "About TriDyme"
  },
  fr: {
    title: `TriDyme | A propos`,
    description: "A propos de TriDyme"
  }
};

const About = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <h1>
      <FormattedMessage id="about" />
    </h1>
    <p>
      <FormattedMessage id="aboutCopy" />
    </p>
    <LocalizedLink to="/">
      <FormattedMessage id="backToHomepage" />
    </LocalizedLink>
  </Layout>
)

About.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default About;
